import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'


interface TokenState {
    value: string | null
}

// Define the initial state using that type
const initialState: TokenState = {
    value: null
}

export const tokenSlice = createSlice({
    initialState,
    name: 'token',
    reducers: {
        registerToken: (state, action: PayloadAction<string>) => {
            state.value = action.payload
        }
    }
})

export const { registerToken } = tokenSlice.actions

export const selectToken = (state: RootState) => state.token.value

export default tokenSlice.reducer