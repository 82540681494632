import loki from 'lokijs';
import { Cart } from '../@types';

const db = new loki('@RON', {
  adapter: new loki.LokiMemoryAdapter(),
});

const cart = db.addCollection<Cart>('cart');

cart.insert({
  name: "@RON:Cart",
  id: "",
  sellerId: "",
  sellerCoupon: "",
  orderFormId: "",
  items: [],
  clientEmail: "",
  link: "",
  total: 0,
  isFinished: false,
  createdAt: new Date(),
  lastChecked: new Date(),
})

export default cart;