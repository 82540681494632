import { lazy, Suspense }from "react";
import { Switch } from "react-router-dom";
import { Loading } from "../components/Loading";
import LoginClientSeller from "../pages/clienteSellerLogin";

import Route from "./Route";

const Login = lazy(() => import ("../pages/Login"));
const NowLogin = lazy(() => import ("../pages/NowLogin"));
const Dashboard = lazy(() => import ("../pages/Dashboard"));
const CartProducts = lazy(() => import ("../pages/Cart/Products"));
const CartFinished = lazy(() => import ("../pages/Cart/Finished"));
const CartView = lazy(() => import ("../pages/Cart/View"));
const InvalidAccess  = lazy(() => import ("../pages/invalidUser"));
const RegisterSeller  = lazy(() => import ("../pages/Seller"));
const NewCart  = lazy(() => import ("../pages/newCart"));
const UserNotFound  = lazy(() => import ("../pages/UserNotExists"));
const RedirectNow  = lazy(() => import ("../pages/RedirectNow"));


export default function Routes() {
  return (
    <Switch>
      <Suspense fallback={ <Loading /> }>
        <Route path="/" exact component={() => <Login />} />
        <Route path="/invalid" exact component={() => <InvalidAccess />} />
        <Route path="/login/:jwt" component={() => <NowLogin />} />
        <Route path="/login/integration/:id" component={() => <LoginClientSeller />} />
        <Route path="/dashboard" isPrivate component={() => <Dashboard />} />
        <Route path='/carts' isPrivate component={() => <CartView />} />
        <Route path='/cart/products' isPrivate component={() => <CartProducts />} />
        <Route path='/cart/finished/:id' isPrivate component={() => <CartFinished />} />
        <Route path="/secret" exact component={() => <RegisterSeller />} />
        <Route path="/newCart" isPrivate exact component={() => <NewCart />} />        
        <Route path="/userNotFound" exact component={() => <UserNotFound />} />
        <Route path="/now" exact component={() => <RedirectNow />} />        
        <Route path='/externalNow' component={() => { window.location.replace('http://191.234.166.206/comerciodigitalcdig06/Login'); return null;} }/>
      </Suspense>
    </Switch>
  );
}