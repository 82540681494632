import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'


interface ShareCartState {
    destination: string,
    cpf: string,
    segment: string,
    client_email: string,
    phone: string,
}

// Define the initial state using that type
const initialState: ShareCartState = {
    destination: '',
    cpf: '',
    segment: '',
    client_email: '',
    phone: ''
}

export const shareCartSlice = createSlice({
    initialState,
    name: 'shareCart',
    reducers: {
        registerShareCart: (state, action: PayloadAction<ShareCartState>) => {
            state.destination = action.payload.destination
            state.cpf = action.payload.cpf
            state.segment = action.payload.segment
            state.client_email = action.payload.client_email
            state.phone = action.payload.phone
        }
    }
})

export const { registerShareCart } = shareCartSlice.actions

export const selectShareCart = (state: RootState) => state.shareCart

export default shareCartSlice.reducer