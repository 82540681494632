import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';


import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  ReactNode,
} from 'react';
import cart from '../database';
import { useAppSelector, useAppDispatch } from '../hooks';
import { registerToken } from '../store/features/token/tokenSlice';
import { registerUser } from '../store/features/user/userSlice';
import { api } from '../services/api';
import { registerShareCart } from '../store/features/shareCart/shareCartSlice';

interface TokenState {
  token: string | null;
}

const initialState: TokenState = {
  token: null
}

interface SignInCredentials {
  cpf: string;
  coupon: string;
  agenda:string;
  token:string;
  cpf_cliente:string;
  company:string;
  remember: boolean;
}

interface NowSignInCredentials {
  token:string;
}

interface User {
  name: string;
  cpf: string;
  coupon: string;
  document: string;
  company:string;
  avatar_url: string;
}

interface ShareCart {
  segment: string;
  cpf: string;
  destination: string;
  client_email: string;
  company:string;
  phone: string;

}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signInSAC(credentials: any): Promise<void>;
  signInNewCart(credentials: any): Promise<void>;
  nowSignIn(credentials: NowSignInCredentials): Promise<void>
  sellerClientSignIn(credentials: any): Promise<void>
  signOut(): void;
  isAuthenticated: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export function AuthProvider({ children }: AuthProviderProps) {
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user);

  const history = useHistory();
  let isAuthenticated = user.name !== '';

  const signIn = useCallback(
    async ({ token,remember}: SignInCredentials): Promise<void> => {
      try {
      dispatch(registerToken(token))
      const userResponse = await api.get(`${process.env.REACT_APP_RON_API}validate/${token}`)
  
        const user = {
          name: userResponse.data.user.user.name,
          cpf: userResponse.data.user.user.cpf_vendedor,
          coupon: userResponse.data.user.user.coupon,
          company: userResponse.data.user.user.company,
          avatar_url: userResponse.data.user.user.name,
        } as User;

        const shareCart = {
          destination: userResponse.data.user.cart.cpf_cliente,
          cpf: userResponse.data.user.user.cpf_vendedor,
          segment: userResponse.data.user.cart.agenda,
          client_email: '',
          phone: '',
        } as ShareCart;

        dispatch(registerUser(user))
        dispatch(registerShareCart(shareCart))

        history.push("/cart/products");
      } catch (err) {
        const error = err as AxiosError;
        if (error.response?.status === 401) {
          toast.warn('Erro ao fazer login, verifique seus dados!');
        } else {
          toast.error('Erro ao se comunicar com o servidor, tente mais tarde.');
        }
      }
    }, [dispatch, history]   
  );

  const signInSAC = useCallback(
    async ({ document, coupon }): Promise<void> => {
      try {
      const userResponse = await api.post(`${process.env.REACT_APP_RON_API}/login/sac`, {
        document, coupon
      })
  
        const user = {
          name: userResponse.data.user.user.name,
          cpf: userResponse.data.user.user.cpf_vendedor,
          coupon: userResponse.data.user.user.coupon,
          company: userResponse.data.user.user.company,
          avatar_url: userResponse.data.user.user.name,
        } as User;

        dispatch(registerUser(user))
        
        history.push("/cart/products");
      } catch (err) {
        const error = err as AxiosError;
        if (error.response?.status === 409) {
          window.location.replace('/now');
          return
        }
        if (error.response?.status === 401) {
          toast.warn('Dados inválidos, verifique seus dados!');
        } else {
          toast.error('Erro ao se comunicar com o servidor, tente mais tarde.');
        }
      }
    }, [dispatch, history]   
  );

  const signInNewCart = useCallback(
    async ({ document, coupon }): Promise<void> => {
      try {
      let userResponse = await api.post(`${process.env.REACT_APP_RON_API}/newCart`, {
        document, coupon
      })      

    

      if(userResponse.data.length === 16) {
        dispatch(registerToken(userResponse.data))
        userResponse = await api.get(`${process.env.REACT_APP_RON_API}/validate/${userResponse.data}`)        
      }      

     
      
        const user = {
          name: userResponse.data.user.user.name,
          cpf: userResponse.data.user.user.cpf_vendedor,
          coupon: userResponse.data.user.user.coupon,
          avatar_url: userResponse.data.user.user.name,
          company: userResponse.data.user.user.company
        } as User;        
        const shareCart = {
          destination: userResponse.data.user.cart.cpf_cliente,
          cpf: userResponse.data.user.user.cpf_vendedor,
          segment: userResponse.data.user.cart.agenda,
          client_email: '',
          phone: ''
        } as ShareCart;

        dispatch(registerUser(user))
        dispatch(registerShareCart(shareCart))
        


        history.push("/cart/products");
      } catch (err) {
        const error = err as AxiosError;
          toast.error('err');
      }
    }, [dispatch, history]   
  );

  const nowSignIn = async(credentials: {token:string}): Promise<void> => {
    try {
      const {token} = credentials
      dispatch(registerToken(token))
      const response = await api.get(`${process.env.REACT_APP_RON_API}/validate/${token}`)


      const user = {
        name: response.data.user.user.name,
        cpf: response.data.user.user.cpf_vendedor,
        coupon: response.data.user.user.coupon,
        avatar_url: response.data.user.user.name,
        company: response.data.user.user.company
      } as User;

      const shareCart = {
        destination: response.data.user.cart.cpf_cliente,
        cpf: response.data.user.user.cpf_vendedor,
        segment: response.data.user.cart.agenda,
        client_email: '',
        phone: ''
      } as ShareCart;

      dispatch(registerUser(user))
      dispatch(registerShareCart(shareCart))



      // cart.findAndUpdate({ name: '@RON:Cart' }, function(cart) {
      //   cart.clientEmail = shareCart.client_email!;
      // });

      history.push("/cart/products");
    } catch (err) {
      window.location.replace('/userNotFound');
    }
  }

  const sellerClientSignIn = async(credentials: {cpf: string, coupon: string,agenda:string,cpf_cliente:string}): Promise<void> => {
    try {
      const {cpf, coupon,agenda,cpf_cliente,} = credentials
      const token = await api.post(
        `${process.env.REACT_APP_RON_API}/login`,
        {
          "cpf_vendedor": `${cpf.replace(/[^\w\s]/gi, '')}`, 
          "coupon": `${coupon}`, 
          "agenda":  `${agenda}`,
          "cpf_cliente": `${cpf_cliente.replace(/[^\w\s]/gi, '')}`
        }
      );

      dispatch(registerToken(token.data))
      const response = await api.get(`${process.env.REACT_APP_RON_API}/validate/${token.data}`)

      const user = {
        name: response.data.user.user.name,
        cpf: response.data.user.user.cpf_vendedor,
        coupon: response.data.user.user.coupon,
        avatar_url: response.data.user.user.name,
        company: response.data.user.user.company
      } as User;
      const shareCart = {
        destination: response.data.user.cart.cpf_cliente,
        cpf: response.data.user.user.cpf_vendedor,
        segment: response.data.user.cart.agenda,
        client_email: '',
        phone: ''
      } as ShareCart;
      
      dispatch(registerUser(user))
      dispatch(registerShareCart(shareCart))

      cart.findAndUpdate({ name: '@RON:Cart' }, function(cart) {
        cart.clientEmail = shareCart.client_email!;
      });

      history.push("/cart/products");
    } catch (err) {
      window.location.replace('/');
    }
  }

  const signOut = useCallback(() => {
    window.location.replace('/');
  }, [])

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        isAuthenticated, 
        signIn, 
        signOut,
        nowSignIn,
        sellerClientSignIn,
        signInSAC,
        signInNewCart,
      }}>
      { children }
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}