import { configureStore } from '@reduxjs/toolkit'
import tokenReducer from './features/token/tokenSlice'
import userReducer from './features/user/userSlice'
import shareCartReducer from './features/shareCart/shareCartSlice'

const store = configureStore({
    reducer: {
        token: tokenReducer,
        user: userReducer,
        shareCart: shareCartReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch



export default store