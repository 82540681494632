import React from 'react';
import ReactGA from 'react-ga';
import './styles/global.scss';
import ReactDOM from 'react-dom';
import { ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";

import App from './App';
import { client } from "./graphql";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from 'react-redux';
import store from './store/store';

ReactGA.initialize('UA-32023552-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <ApolloProvider client={client}>
      <App />
      <ToastContainer />
    </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);