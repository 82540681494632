import useSWR from 'swr';
import axios from 'axios';
import https from 'https';

export const api = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  withCredentials: false,
  baseURL: process.env.REACT_APP_RON_API
});

export function useFetch<Data = any, Error = any>(url: string) {
  const { data, error } = useSWR<Data, Error>(url, async url => {
    const response = await api.get(url);

    return response.data;
  })

  return { data, error }
}