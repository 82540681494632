import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'


interface UserState {
    name: string,
    cpf: string,
    coupon: string,
    document: string,
    company: string,
    avatar_url: string,
}

// Define the initial state using that type
const initialState: UserState = {
    name: '',
    cpf: '',
    coupon: '',
    company:'',
    document: '',
    avatar_url: '',
}

export const userSlice = createSlice({
    initialState,
    name: 'user',
    reducers: {
        registerUser: (state, action: PayloadAction<UserState>) => {
            state.name = action.payload.name
            state.cpf = action.payload.cpf
            state.coupon = action.payload.coupon
            state.company = action.payload.company
            state.avatar_url = action.payload.avatar_url
        }
    }
})

export const { registerUser } = userSlice.actions

export const selectUser = (state: RootState) => state.user

export default userSlice.reducer