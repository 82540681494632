import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";
import { Loading } from '../../components/Loading';
import { api } from '../../services/api';

interface ClientSellerLoginParams {
  id: string;
}

export default function LoginClientSeller() {  
  const { sellerClientSignIn } = useAuth();
  const { id } = useParams<any>();

  useEffect(() => {
    async function ClientSellerLogin() {
      const couch = await api.get(`/login/integration/${id}`);

      const [email_cliente, cpf_cliente, cpf_vendedor, coupon] = couch.data.docs.map((e: { email_cliente: any; cpf_cliente: any; cpf_vendedor: any; coupon: any; }) => ({email_cliente: e.email_cliente, cpf_cliente: e.cpf_cliente, cpf_vendedor: e.cpf_vendedor, coupon: e.coupon}))

      await sellerClientSignIn({cpf: '10857735748', coupon: 'RSV4696'});
    }
    ClientSellerLogin();
  }, [id, sellerClientSignIn])

  return <Loading />
}