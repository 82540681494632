import { TailSpin } from "react-loading-icons";

import styles from './Loading.module.scss';

export function Loading() {
  return (
    <div className={styles.container}>
      <TailSpin />
    </div>
  )
}